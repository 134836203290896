/**
 * localStorage 用到的key，需要先在这里定义，方便管理 <br/>
 * 值需要指定是字符串，且不要重复
 */
enum LK {
  /** 全局token的key */
  JWT_TOKEN = 'JWT_TOKEN',
  /** IDV 开关 */
  IDV_SWITCH = 'idv_switch',
  /** 可回溯开关 */
  REPLAY_SWITCH = 'replay_switch',
  /** 浏览器类型 */
  BROWSER_TYPE = 'browserType',
  /** 浏览器类型 */
  OAUTHBIND_CODE = 'oAuthBindCode',
  /** 保单列表数据标识 */
  PAY_INFO = "payInfo",
  FLAG_FROM_WXPAY_SIGN = "from_wxpay_sign", // 从签约页面返回的flag
  FLAG_FROM_WXPAY_PAYMENT = "from_wxpay_payment", // 从H5支付页面返回的flag

  /**
   * 语言
   */
  GET_LANGUAGE = 'getLanguage',
}

export default LK
