import SN from '@/common/constant/serviceName'
import LK from '@/common/constant/localStorageKey'

let idvConfig = JSON.parse(localStorage.getItem(LK.IDV_SWITCH) || '{}')
let disableReplay = localStorage.getItem(LK.REPLAY_SWITCH) === 'Y'

export default function initHelperPlugin(vconsole, VConsolePlugin) {
  let plugin = new VConsolePlugin('helper', 'Helper')
  plugin.on('renderTab', function (callback) {
    let html = `
    <div>env: ${import.meta.env.MODE}</div>
    <div style='display: flex; align-items: center;'>
      <div>可回溯：<span id='replay_status'>${disableReplay ? '关闭' : '开启'}</span></div>
      <button id='btn_replay' style='font-size: 20px;margin-left: 5em;'>切换</button>
    </div>
    <div style='margin-top: 0em;text-align:center'>
      <div>idv开关</div>
      <table id="idv_swith" style='width: 100%; border-collapse:separate; border-spacing:0px 15px;'>
        <tr>
          <td>退保</td>
          <td id='${SN.SURRENDER}_status'></td>
          <td> <button id='${SN.SURRENDER}' style='font-size: 20px;'>切换</button> </td>
        </tr>
      </table>
    </div>
    `
    callback(html)
  })
  plugin.on('addTool', function (callback) {
    let button = {
      name: '关闭VConsole',
      onClick: function (event) {
        vconsole.destroy()
      }
    }
    callback([button])
  })
  plugin.on('ready', function () {
    // 初始化原始配置
    for (let x in SN) {
      // console.log('servicename', SN[x])
      let el = document.querySelector(`#${SN[x]}_status`)
      if (el) {
        el.innerHTML = idvConfig[SN[x]] ? '关闭' : '打开'
      }
    }
    // 监听点击事件，修改配置
    document.querySelector('#idv_swith').addEventListener('click', function (el) {
      let serviceName = el.target.id
      // console.log('serviceName', serviceName, LK.IDV_SWITCH);
      idvConfig[serviceName] = idvConfig[serviceName] ? false : true
      // console.log('idvConfig', idvConfig);
      document.querySelector(`#${serviceName}_status`).innerHTML = idvConfig[serviceName] ? '关闭' : '打开'
      localStorage.setItem(LK.IDV_SWITCH, JSON.stringify(idvConfig))
    })
    // 监听可回溯
    document.querySelector('#btn_replay').addEventListener('click', function (el) {
      disableReplay = !disableReplay
      document.querySelector('#replay_status').innerHTML = disableReplay ? '关闭' : '开启'
      localStorage.setItem(LK.REPLAY_SWITCH, disableReplay ? 'Y' : 'N')
    })
  })

  return plugin
}
