/**
 * Service Name <br/>
 * 值需要是字符串 <br/>
 */
enum SN {
  /** 更新证件日期 */
  UPDATE_ID_CARD_DATE = "updateIdCardDate",
  /** 退保 */
  SURRENDER = "surrender",
  /** 绑定登录 */
  BINDING_LOGIN = "binding_login",
  /** 手机号更新 */
  UPDATE_MOBILE = "updateMobile",
  /** 通讯和个人信息更新 */
  CLIENT_INFO_CHANGE = "clientInfoChange",
}

export default SN
